import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

import {
  standardWrapper,
  bodyCopy,
  headlineOne,
  headlineTwo,
  colors,
} from "../Utilities"

const ShopPoliciesStyled = styled.div`
  ${standardWrapper};

  h1 {
    ${headlineOne};
    width: 100%;
  }

  h2 {
    ${headlineTwo};
    color: ${colors.colorPrimary};
  }

  p {
    ${bodyCopy};
  }

  ul {
    margin: 5rem auto;
    padding-left: 2.5rem;

    li {
      ${bodyCopy};
      margin-bottom: 2.5rem;
      list-style: disc outside none;
    }
  }
`

const ShopPolicies = (props) => {
  const {
    data: { policies },
  } = props
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Privacy Policy - Drift Out West Fly Fishing"
        description="Protecting your personal information Your privacy is important to us. It is Drift Out West Fly Fishing's policy to respect your privacy regarding any information we may collect while operating our website."
        location={props.location.pathname}
      />
      <ShopPoliciesStyled>
        <div>
          <div>
            <h2>Shipping Policy</h2>
          </div>
          <ReactMarkdown source={policies.shipping_policy} />
        </div>
        <div>
          <div>
            <h2>Refunds, returns, and exchanges</h2>
          </div>
          <ReactMarkdown source={policies.refunds_returns_and_exchanges} />
        </div>
        <div>
          <div>
            <h2>Adventure Bookings</h2>
          </div>
          <ReactMarkdown source={policies.adventures_policy} />
        </div>
      </ShopPoliciesStyled>
    </Layout>
  )
}

export const shopPoliciesQuery = graphql`
  {
    policies: strapiShopPolicies {
      shipping_policy
      refunds_returns_and_exchanges
      adventures_policy
    }
  }
`

export default ShopPolicies
